import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'antd';
import './Program.css';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	programeImage: {
		maxHeight: '300px',
		width: '100%'
	}
});

function ProgHomework() {
	const classes = useStyles();
	const programImg = [ '/assets/prog-homework.png' ];
	const img = [ '/assets/slide-1.jpeg' ];

	return (
		<div className="style-1">
			<div className="style-2">
				<img src={img[0]} style={{ width: '100%' }} />
			</div>

			<Typography variant="h5" gutterBottom>
				功課輔導班 (P 1 – P 6 )
			</Typography>

			<Row>
				<Col xs={24} sm={14} md={16} lg={18} xl={18}>
					<Typography variant="body1" gutterBottom>
						以小組形式進行，導師先指導學員完成功課，然後根據進度溫習默書及測驗，亦會提供課外練習，幫助學生鞏固基礎。
					</Typography>{' '}
					<br />
					<Typography variant="subtitle1" gutterBottom>
						師資保證 → 導師均具有大專或以上資歷
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						小班教學 → 導師能緊貼學生的進度，因材施教
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						試前溫習 → 大量各區學校試題，為應試做足準備
					</Typography>
				</Col>

				<Col xs={24} sm={10} md={8} lg={6} xl={6}>
					<img className="programe-image" src={programImg[0]} />
				</Col>
			</Row>
		</div>
	);
}

export default ProgHomework;
