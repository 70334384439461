import { React, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { API_URL } from '../../config'; // api url
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch'
		}
	}
}));

function Login() {
	const [ inputs, setInputs ] = useState({});
	const [ invalidEmail, isInvalidEmail ] = useState();
	const [ invalidPassword, isInvalidPassword ] = useState();

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;

		setInputs((values) => ({ ...values, [name]: value }));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		var isValidForm = true;
		const isEmpty = Object.keys(inputs).length === 0;

		console.log(API_URL);

		if (isEmpty) {
			isInvalidEmail(true);
			isInvalidPassword(true);
			isValidForm = false;
			return;
		}

		if (inputs.email || inputs.email === '') {
			var isEmail = validateEmail(inputs.email);
			if (isEmail == false) {
				isInvalidEmail(true);
				isValidForm = false;
			} else {
				isInvalidEmail(false);
			}
		} else {
			isInvalidEmail(true);
			isValidForm = false;
		}

		if (inputs.password) {
			isInvalidPassword(false);
		} else {
			isInvalidPassword(true);
			isValidForm = false;
		}
		// post to server
		if (isValidForm) {
			tryLogin(inputs);
		}
		console.log(inputs);
		// alert(isValidForm);
	};

	const classes = useStyles();

	return (
		<form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
			<TextField
				id="standard-basic"
				error={invalidEmail}
				required
				name="email"
				type="email"
				value={inputs.email || ''}
				onChange={handleChange}
				label="Email"
			/>

			<TextField
				id="standard-password-input"
				error={invalidPassword}
				required
				label="Password"
				type="password"
				name="password"
				value={inputs.password || ''}
				onChange={handleChange}
			/>

			<input type="submit" />
		</form>
	);
}

function validateEmail(email) {
	const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regexp.test(email);
}

function tryLogin(userInfo) {
	let url = API_URL + '/api/login';

	axios
		.post(url, {
			userInfo
		})
		.then((res) => {
			if (res.data.isLoggedIn) {
				localStorage.setItem('iaToken', res.data.token);
				window.location.replace('/dashboard');
			}
		});
}
export default Login;
