import React, { useState } from 'react';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import './ImageSlider.css';

const ImageSlider = () => {
	const [ autoplay, setAutoplay ] = useState(true);

	const images = [
		{img: '/assets/slide-1.jpeg', programe: '/prog/cambridge'},
		{img: '/assets/slide-grammar.jpeg', programe: '/prog/grammar'},
		{img: '/assets/slide-OWL.jpeg', programe: '/prog/writing'},
		{img: '/assets/slide-phonics.jpeg', programe: '/prog/phonics'},
		{img: '/assets/slide-vocab.jpeg', programe: '/prog/vocab'},
		{img: '/assets/slide-GAPSK.jpeg', programe: '/prog/gapsk'},
	];

	const mobileImages = [
		{img: '/assets/slide-grammar2.jpeg', programe: '/prog/grammar'},
	];

	return (
		<div className="image-slider">
			<div className="image-slider-normal">
				<Slide autoplay={autoplay}>
					{images.map((each, index) => (
						<div key={index} style={{ width: '100%' }}>
							<a href={each.programe}>
								<img style={{ objectFit: 'cover', width: '100%' }} src={each.img} />
							</a>
						</div>
					))}
				</Slide>
			</div>
			<div className="image-slider-mobile">
				<Slide autoplay={autoplay}>
					{mobileImages.map((each, index) => (
						<div key={index} style={{ width: '100%' }}>
							<a href={each.programe}>
								<img style={{ objectFit: 'cover', width: '100%' }} src={each.img} />
							</a>
						</div>
					))}
				</Slide>
			</div>

			{/* <div className="autoplay-buttons">Autplay is {autoplay ? 'on' : 'off'}</div>
                <div className="autoplay-buttons">
                    <button type="button" onClick={() => setAutoplay(false)}>
                        Pause
                    </button>
                    <button type="button" onClick={() => setAutoplay(true)}>
                        Play
                    </button>
                </div> */}
		</div>
	);
};

export default ImageSlider;
