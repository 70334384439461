import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { WhatsAppOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

const useStyles = makeStyles({
	root: {
		marginTop: 24
	},
	title: {
		fontSize: 18
	},
	pos: {
		marginBottom: 12
	}
});

function ContactUs() {
	const classes = useStyles();

	return (
		<div style={{ width: '100%' }}>
			<iframe
				src="https://docs.google.com/forms/d/e/1FAIpQLSdKphMXM-usU9h6x3y66Ak1ldVcG8hyDJPc5PcyAGkDXLLdLw/viewform?embedded=true"
				width="100%"
				height="1000"
				frameBorder="0"
				marginHeight="0"
				marginWidth="0"
			>
				Loading…
			</iframe>

			<Card className={classes.root}>
				<CardContent>
					<Typography className={classes.title} gutterBottom>
						Head office
					</Typography>
					<Typography variant="body1" className={classes.pos} color="textSecondary">
						Address: Office M, 7/F, Kings Wing Plaza 2, Shek Mun, Sha Tin, New Territories, Hong Kong
					</Typography>
					<Typography variant="body1" className={classes.pos} color="textSecondary" style={{ color: 'grey' }}>
						<MailOutlined />:{' '}
						<a href="mailto: info@inspireacademyhk.com" style={{ color: 'gray' }}>
							info@inspireacademyhk.com
						</a>{' '}
						<br />
						<a href={'https://wa.me/85291375640'} target="_blank" style={{ color: 'green', fontSize: 20 }}>
							<WhatsAppOutlined />: 9137 5640 (Chat Now)
						</a>
						<br />
						<PhoneOutlined />: 9137 5640
					</Typography>
				</CardContent>
			</Card>
		</div>
	);
}

export default ContactUs;
