import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'antd';
import './Program.css';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	programeImage: {
		maxHeight: '300px',
		width: '100%'
	}
});

function ProgGrammar() {
	const classes = useStyles();
	const programImg = [ '/assets/prog-grammar.png' ];
	const img = [ '/assets/slide-grammar.jpeg' ];

	return (
		<div className="style-1">
			<div className="style-2">
				<img src={img[0]} style={{ width: '100%' }} />
			</div>

			<Typography variant="h5" gutterBottom>
				Grammar
			</Typography>

			<Row>
				<Col xs={24} sm={14} md={16} lg={18} xl={18}>
					<Typography variant="body1" gutterBottom>
						<ul>
							<li>Crawler (P1 – P2)</li>
							<li>Walker (P2 – P3)</li>
							<li>Speeder (P4 – P6)</li>
						</ul>
						一般香港學生都會對英文文法的運用感到難以掌握，文法基礎薄弱導致學生的英語水平欠佳。因此，若要學好英語，先要鞏固文法基礎。為了有效提升學生的英語文法水平，本中心精心設計啟發英文文法課程，根據香港小學課程所覆蓋之教學內容，編寫有系統的課程大綱，重點教授英語文法結構及應用方法，全面覆蓋各文法專題，協助學生循序漸進地掌握英文文法。
					</Typography>
					<Typography variant="body1" gutterBottom>
						<ol>
							<li>系統性的課程綱領，全面覆蓋各文法題目，為日後英文學習打好基礎</li>
							<li>內容豐富，由淺入深，能真正幫助學生提升英語水平</li>
							<li>透過精編教材及導師解說，學生更能熟習文法要點和應用上須注意的地方</li>
							<li>有助於提昇作文技巧，使句子結構更有系統，文章更為通順</li>
						</ol>
					</Typography>
				</Col>

				<Col xs={24} sm={10} md={8} lg={6} xl={6}>
					<img className="programe-image" src={programImg[0]} />
				</Col>
			</Row>
		</div>
	);
}

export default ProgGrammar;
