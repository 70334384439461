import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Row, Col, Card, Typography, BackTop, Divider } from 'antd';
import './programGroup.css';

const useStyles = makeStyles((theme) => ({
	programeImage: {
		maxHeight: '340px',
		width: '100%'
	}
}));

function ProgrameGroup() {
	const classes = useStyles();
	const programImg = [
		{ url: '/prog/cambridge', img: '/assets/prog-cambridge.png' },
		{ url: '/prog/vocab', img: '/assets/prog-vocab.png' },
		{ url: '/prog/phonics', img: '/assets/prog-phonics.png' },
		{ url: '/prog/grammar', img: '/assets/prog-grammar.png' },
		{ url: '/prog/writing', img: '/assets/prog-writing.png' },
		{ url: '/prog/storytelling', img: '/assets/prog-storytelling.png' },
		{ url: '/prog/homework', img: '/assets/prog-homework.png' },
		{ url: '/info', img: '/assets/prog-math.png' },
		{ url: '/prog/gapsk', img: '/assets/prog-gapsk.png' }
	];

	return (
		<div>
			<Row style={{ padding: '24px 0' }}>
				{programImg.map((programe, index) => (
					<Col key={index} xs={0} sm={8} md={6} lg={6} xl={6}>
						<center>
							<a href={programe.url}>
								<img className="program-icon" src={programe.img} />
							</a>
						</center>
					</Col>
				))}
				{programImg.map((programe, index) => (
					<Col key={index} xs={24} sm={0} md={0} lg={0} xl={0}>
						<center>
							<a href={programe.url}>
								<img className="program-icon2" src={programe.img} style={{ padding: '12px' }} />
							</a>
						</center>
					</Col>
				))}
			</Row>
		</div>
	);
}

export default ProgrameGroup;
