import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'antd';
import './Program.css';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	programeImage: {
		maxHeight: '300px',
		width: '100%'
	}
});

function ProgStorytelling() {
	const classes = useStyles();
	const programImg = [ '/assets/prog-storytelling.png' ];
	const img = [ '/assets/slide-1.jpeg' ];

	return (
		<div className="style-1">
			<div className="style-2">
				<img src={img[0]} style={{ width: '100%' }} />
			</div>

			<Typography variant="h5" gutterBottom>
				Storytelling 英文故事閱讀 (K1 – K3 )
			</Typography>

			<Row>
				<Col xs={24} sm={14} md={16} lg={18} xl={18}>
					<Typography variant="body1" gutterBottom>
						課程透過閱讀英語故事， 掌握英語發音技巧，增加學員的英文詞彙量， 並激發學員對英語閱讀的興趣，提升運用英語的信心。 主要內容包括： 故事閱讀、詞彙練習、 小組遊戲等。
					</Typography>
				</Col>

				<Col xs={24} sm={10} md={8} lg={6} xl={6}>
					<img className="programe-image" src={programImg[0]} />
				</Col>
			</Row>
		</div>
	);
}

export default ProgStorytelling;
