import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'antd';
import './Program.css';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	programeImage: {
		maxHeight: '300px',
		width: '100%'
	}
});

function ProgWriting() {
	const classes = useStyles();
	const programImg = [ '/assets/prog-writing.png' ];
	const img = [ '/assets/slide-OWL.jpeg' ];

	return (
		<div className="style-1">
			<div className="style-2">
				<img src={img[0]} style={{ width: '100%' }} />
			</div>

			<Typography variant="h5" gutterBottom>
				OWL Writing Program英語寫作
			</Typography>

			<Row>
				<Col xs={24} sm={14} md={16} lg={18} xl={18}>
					<Typography variant="body1" gutterBottom>
						OWL (Oral + Writing + Listening) 為小學生提供針對英文寫作的訓練，高效提升學生寫作的能力。<br />
						課程以不同的趣味主題教學，讓同學學習豐富的主題詞彙及句子，並寫出各類型的好文章。<br />
						OWL每堂教授學生字及句式，將學到的詞彙用於寫作，日積月累，建立個人字句庫。最後透過引導性的訓練，有效增強學生的寫作能力。
						<Typography variant="subtitle1" gutterBottom>
							Learning Outcomes:
						</Typography>
						<ul>
							<li>Vocabulary</li>
							<li>Story telling in English – improve pronunciation</li>
							<li>Writing based on theme</li>
						</ul>
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						課程全以英語授教<br />
						學生必需通過評估才能註冊課程
					</Typography>
				</Col>

				<Col xs={24} sm={10} md={8} lg={6} xl={6}>
					<img className="programe-image" src={programImg[0]} />
				</Col>
			</Row>
		</div>
	);
}

export default ProgWriting;
