import React, { useState, useEffect } from 'react';
import ImageSlider from './Components/ImageSlider';
import ProgrameGroup from './Components/ProgramGroup';
import { Container } from '@material-ui/core';


function Home() {
	return (
		<div>
			<div>
				<ImageSlider />
				<Container maxWidth="lg">
					<ProgrameGroup />
				</Container>
			</div>
		</div>
	);
}

export default Home;
