import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	topSpacing: {
		paddingTop: '36px'
	}
});

function AboutUs() {
	const classes = useStyles();
	return (
		<div className={classes.topSpacing}>
			<Typography variant="h5" gutterBottom>
				關於我們 / 成立背景
			</Typography>
			<Typography variant="body1" gutterBottom>
				Inspire Academy 創辦人擁有多年補習經驗，一直以提供優質教育服務的宗旨來培育新一代，期望成為一間深受家長認同的教學團體。有鑑於此，Inspire
				Academy匯聚不同導師提供價格相宜的小組補習班，令更多學生能夠得到應有的學術指導。
				因應學生不同的天資、性格和態度，採用合適的教學策略指導學生，使學生的學習成效事半功倍。我們的目標是希望學生能掌握所學，活學活用，不但在校內成績有所提升，更希望啟發他們能不斷探索、追尋新知識。
			</Typography>
		</div>
	);
}

export default AboutUs;
