import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'antd';
import './Program.css';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	programeImage: {
		maxHeight: '300px',
		width: '100%'
	}
});

function ProgCambridge() {
	const classes = useStyles();
	const programImg = [ '/assets/prog-cambridge.png' ];
	const img = [ '/assets/slide-1.jpeg' ];

	return (
		<div className="style-1">
			<div className="style-2">
				<img src={img[0]} style={{ width: '100%' }} />
			</div>

			<Typography variant="h5" gutterBottom>
				Cambridge English Exam (K 1 – P 5 )
			</Typography>
			<Row>
				<Col xs={24} sm={14} md={16} lg={18} xl={18}>
					<Typography variant="body1" gutterBottom>
						Specially designed for learners to meet requirements of Cambridge English: Young Learners
						English (YLE), these exam preparatory courses use Cambridge English Exam textbooks as teaching
						materials to focus training on vocabulary lists. YLE are designed for children between the ages
						of 4 to 12. The qualifications will encourage your child to learn English from an early age and
						create a positive attitude to language qualifications. Focus training is provided to learners on
						exam question types and tasks, so as to strengthen their exam skills as well as enhance
						confidence for the exam.
					</Typography>
					<Typography variant="h6" gutterBottom>
						Pre A1 Starters – start of a child’s language learning journey <br />
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						Who should take this qualification?
					</Typography>
					<Typography variant="body1" gutterBottom>
						Children who take the qualification are usually between the ages of 4 and 6, and are studying
						Kindergarten 2 to Primary 1 in Hong Kong. <br />
						<br />
						In the qualification your child will need to:
						<ul>
							<li>recognise colours in English</li>
							<li>answer very simple questions about themselves</li>
							<li>write short one-word answers to basic questions.</li>
						</ul>
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						What happens when my child takes this qualification?
					</Typography>
					<Typography variant="body1" gutterBottom>
						There is no pass or fail for the qualification. Your child can get a maximum of five shields for
						each part of the qualification. The number of shields they are awarded for each part will be
						shown on their certificate. They will receive the certificate about six to eight weeks after
						taking the qualification.
					</Typography>
				</Col>

				<Col xs={24} sm={10} md={8} lg={6} xl={6}>
					<img className="programe-image" src={programImg[0]} />
				</Col>
			</Row>
		</div>
	);
}

export default ProgCambridge;
