import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'antd';
import './Program.css';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	programeImage: {
		maxHeight: '300px',
		width: '100%'
	}
});

function ProgGapsk() {
	const classes = useStyles();
	const programImg = [ '/assets/prog-gapsk.png' ];
	const img = [ '/assets/slide-GAPSK.jpeg' ];

	return (
		<div className="style-1">
			<div className="style-2">
				<img src={img[0]} style={{ width: '100%' }} />
			</div>

			<Typography variant="h5" gutterBottom>
				GAPSK 普通話應試班(Aged 3 – 6)
			</Typography>
			<Row>
				<Col xs={24} sm={14} md={16} lg={18} xl={18}>
					<Typography variant="body1" gutterBottom>
						我們的GAPSK
						課程通過提供專業的普通話導師指導和有趣的備考材料，幫助學生掌握普通話會話中的用語，分為三級，考核學生聽、說、讀三方面的普通話應用能力。本課程專為參與該測試之學員而設，採用考試部門所提供的教材，
						讓學員掌握考試的內容、瞭解考試形式而考獲證書。
					</Typography>
				</Col>

				<Col xs={24} sm={10} md={8} lg={6} xl={6}>
					<img className="programe-image" src={programImg[0]} />
				</Col>
			</Row>
		</div>
	);
}

export default ProgGapsk;
