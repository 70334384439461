import { Row, Col, BackTop } from 'antd';

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import { FacebookOutlined, WhatsAppOutlined, InstagramOutlined } from '@ant-design/icons';

function Copyright() {
	return (
		<div>
			<Typography variant="body1">
				地址： 沙田石門京瑞廣場二期7樓M室
			</Typography>
			<Typography variant="body2">
				{'Copyright © '}
				<Link color="inherit" href="/">
					Inspire Academy(HK)
				</Link>{' '}
				{new Date().getFullYear()}
				{'.'} | All Rights Reserved
			</Typography>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '80vh'
	},
	main: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(2)
	},
	footer: {
		padding: theme.spacing(3, 2),
		marginTop: 'auto',
		background: '#c3e2fd'
		// backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]
	},
	footerLink: {
		color: 'black'
	}
}));

function FooterInfo() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<footer className={classes.footer}>
				<center>
					<Container maxWidth="lg">
						<Row>
							<Col xs={12} sm={6} md={6} lg={6} xl={6}>
								<Typography variant="body1">
									<a href="/about-us" className={classes.footerLink}>
										關於我們
									</a>
								</Typography>
							</Col>
							<Col xs={12} sm={6} md={6} lg={6} xl={6}>
								<Typography variant="body1">
									<a href="/info" className={classes.footerLink}>
										常見問題
									</a>
								</Typography>
							</Col>
							<Col xs={12} sm={6} md={6} lg={6} xl={6}>
								<Typography variant="body1">
									<a href="/info" className={classes.footerLink}>
										最新消息
									</a>
								</Typography>
							</Col>
							<Col xs={12} sm={6} md={6} lg={6} xl={6}>
								<Typography variant="body1">
									<a target="_blank" href="https://www.facebook.com/Inspire-academy-105536408792614" className={classes.footerLink}>
										<FacebookOutlined style={{ fontSize: '20px', color: '#08c' }}/>
									</a>
									<a target="_blank" href="https://wa.me/85291375640" className={classes.footerLink}>
										<WhatsAppOutlined style={{ fontSize: '20px', color: '#08c', padding: '0 10px' }}/>
									</a>
									<a target="_blank" href="https://www.instagram.com/inspireacademyhk/?hl=en" className={classes.footerLink}>
										<InstagramOutlined style={{ fontSize: '20px', color: '#08c' }}/>
									</a>
								</Typography>
							</Col>
						</Row>
						<div style={{ padding: '6px 0' }}> </div>
						<Divider light />
						<div style={{ padding: '6px 0' }}> </div>

						<Copyright />
					</Container>
				</center>
			</footer>

			<BackTop />
		</div>
	);
}

export default FooterInfo;
