import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'antd';
import './Program.css';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	programeImage: {
		maxHeight: '300px',
		width: '100%'
	}
});

function ProgPhonics() {
	const classes = useStyles();
	const programImg = [ '/assets/prog-phonics.png' ];
	const img = [ '/assets/slide-phonics.jpeg' ];

	return (
		<div className="style-1">
			<div className="style-2">
				<img src={img[0]} style={{ width: '100%' }} />
			</div>

			<Typography variant="h5" gutterBottom>
				Oxford Phonics K2 – P4
			</Typography>

			<Row>
				<Col xs={24} sm={14} md={16} lg={18} xl={18}>
					<Typography variant="body1" gutterBottom>
						Oxford Phonics教授有效的學習方法， 訓練學生看字知音，協助學員正確發音，提升會話及聆聽英語的信心。
					</Typography>
					<Typography variant="body1" gutterBottom>
						拼音班訓練學生對聲音的敏感度，接著才帶入認字拼讀教學，讓學生能夠於短時間內增強學生拼讀和串字能力，進而培養獨立拼讀能力，以及提升聽寫默書準確度。並且不只注重讀書方面，亦十分著重聽聲默寫技巧。我們的老師會循序漸進地教授，令學生不自覺地掌握聽聲默寫技巧。
					</Typography>
				</Col>

				<Col xs={24} sm={10} md={8} lg={6} xl={6}>
					<img className="programe-image" src={programImg[0]} />
				</Col>
			</Row>
		</div>
	);
}

export default ProgPhonics;
