import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'antd';
import './Program.css';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	programeImage: {
		maxHeight: '300px',
		width: '100%'
	}
});

function ProgVocab() {
	const classes = useStyles();
	const programImg = [ '/assets/prog-vocab.png' ];
	const img = [ '/assets/slide-vocab.jpeg' ];

	return (
		<div className="style-1">
			<div className="style-2">
				<img src={img[0]} style={{ width: '100%' }} />
			</div>

			<Typography variant="h5" gutterBottom>
				Vocabulary
			</Typography>

			<Row>
				<Col xs={24} sm={14} md={16} lg={18} xl={18}>
					<Typography variant="body1" gutterBottom>
						<ul>
							<li>Crawler (K3)</li>
							<li>Walker (P1 – P2)</li>
							<li>Jogger (P3 – P4)</li>
							<li>Speeder (P5 – P6)</li>
						</ul>
					</Typography>
					<Typography variant="subtitle1" gutterBottom>
						課程目標
					</Typography>
					<Typography variant="body1" gutterBottom>
						教授學員最基礎的英語知識，讓學員認識英文字母的正確發音、常用基本字詞及短句，從而提高學員對學習英語的信心
					</Typography>

					<Typography variant="subtitle1" gutterBottom>
						課程內容
					</Typography>
					<Typography variant="body1" gutterBottom>
						<ol>
							<li>認識英文字母</li>
							<li>英文字母正確發音</li>
							<li>常用基本字詞(數字，身體部份，顏色，方向，情緒，動物，辦公室及家庭用品等)</li>
							<li>常用代名詞，形容詞及動詞及基本字詞讀音</li>
							<li>認識基本句子結構及生活工作常用短句朗讀</li>
						</ol>
					</Typography>
				</Col>

				<Col xs={24} sm={10} md={8} lg={6} xl={6}>
					<img className="programe-image" src={programImg[0]} />
				</Col>
			</Row>
		</div>
	);
}

export default ProgVocab;
