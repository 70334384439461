import './App.css';
import './index.css';
import AllRoutes from './Containers/Router';


function App() {
  return (
    <AllRoutes />
  )
}

export default App;