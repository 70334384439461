import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import './Router.css';
import FooterInfo from './Components/FooterInfo';
import NavBar from './Components/NavBar';

import Home from './Home';
import AboutUs from './AboutUs';
import ScrollToTop from './Components/ScrollToTop';
import ProgCambridge from './Components/ProgCambridge';
import ProgVocab from './Components/ProgVocab';
import ProgGrammar from './Components/ProgGrammar';
import ProgPhonics from './Components/ProgPhonics';
import ProgWriting from './Components/ProgWriting';
import ProgStorytelling from './Components/ProgStorytelling';
import ProgHomework from './Components/ProgHomework';
import ContactUs from './ContactUs';
import Info from './Components/Info';
import Login from './Components/Login';
import Dashboard from './Dashboard';
import ProgGapsk from './Components/ProgGapsk';

const useStyles = makeStyles({});

function AllRoutes() {
	const classes = useStyles();
	const [ value, setValue ] = React.useState('home');

	var isLoggedIn = false;
	if (localStorage.getItem('iaToken')) {
		isLoggedIn = true;
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<NavBar />
			
				<Router>
					<ScrollToTop>

						<Switch>
						<Route path="/about-us">
							<Container maxWidth="lg">
								<div className="content-layout">
									<AboutUs />
								</div>
							</Container>
						</Route>
						<Route path="/contact-us">
							<Container maxWidth="lg">
								<div className="content-layout">
									<ContactUs />
								</div>
							</Container>
						</Route>
						<Route path="/prog/cambridge">
							<Container maxWidth="lg">
								<div className="content-layout">
									<ProgCambridge />
								</div>
							</Container>
						</Route>
						<Route path="/prog/vocab">
							<Container maxWidth="lg">
								<div className="content-layout">
									<ProgVocab />
								</div>
							</Container>
						</Route>
						<Route path="/prog/grammar">
							<Container maxWidth="lg">
								<div className="content-layout">
									<ProgGrammar />
								</div>
							</Container>
						</Route>
						<Route path="/prog/phonics">
							<Container maxWidth="lg">
								<div className="content-layout">
									<ProgPhonics />
								</div>
							</Container>
						</Route>
						<Route path="/prog/writing">
							<Container maxWidth="lg">
								<div className="content-layout">
									<ProgWriting />
								</div>
							</Container>
						</Route>
						<Route path="/prog/storytelling">
							<Container maxWidth="lg">
								<div className="content-layout">
									<ProgStorytelling />
								</div>
							</Container>
						</Route>
						<Route path="/prog/homework">
								<Container maxWidth="lg">
								<div className="content-layout">
									<ProgHomework />
								</div>
							</Container>
						</Route>
						<Route path="/prog/gapsk">
							<Container maxWidth="lg">
								<div className="content-layout">
									<ProgGapsk />
								</div>
							</Container>
						</Route>
						<Route path="/info">
							<Container maxWidth="lg">
								<Info />
							</Container>
						</Route>

							{isLoggedIn ? (
								<Route path="/dashboard">
									<Dashboard />
								</Route>
							) : (
								<Route path="/login">
									<Login />
								</Route>
							)}

							<Route path="/">
								<Home />
							</Route>
						</Switch>
					</ScrollToTop>
				</Router>

				<FooterInfo />
			
		</div>
	);
}

export default AllRoutes;
