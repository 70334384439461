import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Alert, Button, Space } from 'antd';

const useStyles = makeStyles({
	topSpacing: {
		paddingTop: '36px'
	}
});

function Info() {
	const classes = useStyles();
	return (
		<div className={classes.topSpacing}>
			<Alert
				message="消息"
				description="更多功能正在建設中， 敬請期待！"
				type="info"
				action={
					<Space>
						<Button size="small" type="ghost">
							<a href="/">繼續訪問</a>
						</Button>
					</Space>
				}
			/>
		</div>
	);
}

export default Info;
