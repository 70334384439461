import React, { useState, useEffect } from 'react';

import { Card, Row, Col, Typography } from 'antd';
import ReactPlayer from 'react-player';

const { Meta } = Card;
const { Paragraph, Text } = Typography;

function Test(data) {
	console.log(data);
	return (	
		<div>
			
			{/* <ReactPlayer
				url={'http://localhost:3001' + data[0].video_url}
				config={{ file: { attributes: { controlsList: 'nodownload' } } }}
				// Disable right click
				onContextMenu={(e) => e.preventDefault()}
				controls
			/> */}
			Test
		</div>
	);
}
function VideoCard(props) {
	// const dataFoler = 'http://52.15.219.202:3001/static/project/';
	const data = props.data;
	console.log(props.data);
	const [ isLoading, setIsLoading ] = useState(true);

	return (
		<div>
			{/* {isLoading ? (
				<div>Loading...</div>
			) : ( */}
			<div>

						<ReactPlayer
							url={'http://localhost:3001/static/project/' + data.video_url}
							config={{ file: { attributes: { controlsList: 'nodownload' } } }}
							// Disable right click
							onContextMenu={(e) => e.preventDefault()}
							controls
						/>
				</div>
			{/* )} */}
		</div>
	);
}

export default VideoCard;
