import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Avatar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Row, Col, Card, Typography, BackTop, Divider, Title, Button } from 'antd';
import Link from '@material-ui/core/Link';
import { WhatsAppOutlined, MenuOutlined } from '@ant-design/icons';

import { Menu, Dropdown } from 'antd';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
		// padding: '24px 0'

		// height: '128px'
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary
	},
	webLogoStyle: {
		minWidth: '128px',
		maxHeight: '76px'
	},
	navImg: {
		height: '36px',
		width: '36px'
	},
	navText: {
		fontFamily: 'AdobeHeitiStd-Regular, 微軟正黑體',
		fontWeight: 'bold',
		fontSize: '18px',
		color: '#3bb34a'
		// padding: '0 4px'
	},
	fullMenuText: {
		fontWeight: 'bold',
		fontSize: '24px',
		color: '#3bb34a'
	}
}));

const aboutList = [ { text: '關於我們', url: '/about-us' }, { text: '常見問題', url: '/info' } ];
const aboutUsMenu = (
	<Menu>
		{aboutList.map((each, index) => (
			<Menu.Item key={index}>
				<a href={each.url}>{each.text}</a>
			</Menu.Item>
		))}
	</Menu>
);

const lessonList = [
	{ text: '劍橋英文證書課程', url: '/prog/cambridge' },
	{ text: '詞彙讀寫課程', url: '/prog/vocab' },
	{ text: '拼音讀寫證書課程', url: '/prog/phonics' },
	{ text: '英語文法操練課程', url: '/prog/grammar' },
	{ text: '英語寫作創意課程', url: '/prog/writing' },
	{ text: '英文故事趣味班', url: '/prog/storytelling' },
	{ text: '功課輔導班', url: '/prog/homework' },
	{ text: '啟發數學班', url: '/info' },
	{ text: '普通話應試班', url: '/prog/gapsk' }
];

const lessonMenu = (
	<Menu>
		{lessonList.map((each, index) => (
			<Menu.Item key={index}>
				<a href={each.url}>{each.text}</a>
			</Menu.Item>
		))}
	</Menu>
);

const { SubMenu } = Menu;
const fullMenu = (
	<Menu>
		<SubMenu title="關於我們">
			{aboutList.map((each, index) => (
				<Menu.Item key={index}>
					<a href={each.url}>{each.text}</a>
				</Menu.Item>
			))}
		</SubMenu>
		<SubMenu title="課程資料">
			{lessonList.map((each, index) => (
				<Menu.Item key={index}>
					<a href={each.url}>{each.text}</a>
				</Menu.Item>
			))}
		</SubMenu>
		<Menu.Item>
			<a rel="noopener noreferrer" href="/info">
				最新消息
			</a>
		</Menu.Item>
		<Menu.Item>
			<a rel="noopener noreferrer" href="/contact-us">
				立即查詢
			</a>
		</Menu.Item>
	</Menu>
);

function NavBar() {
	const classes = useStyles();
	const icons = [ '/assets/logo-2.jpeg', '/assets/about-us.png', '/assets/program-info.png', '/assets/news.png', '/assets/inquiry.png' ];

	const { Title, Paragraph, Text, Link } = Typography;

	function MouseOver(event) {
		event.target.style.color = '#2ad45d';
	}
	function MouseOut(event) {
		event.target.style.color = '#3bb34a';
	}
	return (
		<div style={{boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'}}>
			<div className={classes.root}>
				<Row>
					<Col xs={22} sm={22} md={7} lg={10} xl={10}>
						<Link href="/">
							<img src={icons[0]} className={classes.webLogoStyle} style={{ padding: '8px 0 0 15px' }} />{' '}
						</Link>
					</Col>
					<Col xs={2} sm={2} md={0} lg={0} xl={0}>
						<div style={{ paddingTop: '34px' }} />
						<Dropdown overlay={fullMenu}>
							<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
								<Text onMouseOver={MouseOver} onMouseOut={MouseOut} className={classes.fullMenuText}>
									<MenuOutlined />
								</Text>
							</a>
						</Dropdown>
					</Col>
					<Col xs={0} sm={0} md={17} lg={14} xl={14}>
						<div style={{ height: '34px' }} />
						<center>
							<Box component="div" display="inline" style={{ margin: '0 6px' }}>
								<Dropdown overlay={aboutUsMenu}>
									<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
										<img src={icons[1]} className={classes.navImg} />
										<Text onMouseOver={MouseOver} onMouseOut={MouseOut} className={classes.navText}>
											關於我們
										</Text>
									</a>
								</Dropdown>
							</Box>
							<Box component="div" display="inline" style={{ margin: '0 6px' }}>
								<Dropdown overlay={lessonMenu}>
									<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
										<img src={icons[2]} className={classes.navImg} />
										<Text onMouseOver={MouseOver} onMouseOut={MouseOut} className={classes.navText}>
											課程資料
										</Text>
									</a>
								</Dropdown>
							</Box>
							<Box component="div" display="inline" style={{ margin: '0 6px' }}>
								<a href="/info">
									<img src={icons[3]} className={classes.navImg} />
									<Text onMouseOver={MouseOver} onMouseOut={MouseOut} className={classes.navText}>
										最新消息
									</Text>
								</a>
							</Box>
							<Box component="div" display="inline" style={{ margin: '0 6px' }}>
								<a href="/contact-us">
									<img src={icons[4]} className={classes.navImg} />
									<Text onMouseOver={MouseOver} onMouseOut={MouseOut} className={classes.navText}>
										立即查詢
									</Text>
								</a>
							</Box>
						</center>
						{/* <Box component="div" display="inline" style={{ margin: '0 4px' }}>
							<a href={'https://wa.me/85291375640'} target="_blank">
								<WhatsAppOutlined
									style={{ fontSize: '26px', padding: '2px 4px 0 0', color: 'green' }}
								/>
								<Text onMouseOver={MouseOver} onMouseOut={MouseOut} className={classes.navText}>
									Chat
								</Text>
							</a>
						</Box> */}
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default NavBar;
